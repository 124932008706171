import React, { useEffect, useState } from "react";
import Link from "next/link";
import { setShowAppointment } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

function Sidebar() {
  const dispatch = useDispatch();
  const [inputMenu, setMenu] = useState(false);
  const [inputMenuu, setMenuu] = useState();
  const [sidebarMenu, setSidebarMenu] = useState();
  const [permissions, setPermissions] = useState();
  const { data: session } = useSession();
  const router = useRouter();

  const newSideBarHide = (e) => {
    const sidebar = $(".pc-sidebar");
    if (sidebar.hasClass("pc-sidebar-hide")) {
      sidebar.removeClass("pc-sidebar-hide");
    } else {
      sidebar.addClass("pc-sidebar-hide");
    }
  };

  useEffect(() => {
    setSidebarMenu(localStorage.getItem("activeMenu"));
    setPermissions(session?.user?.permissions);
  }, [session]);

  useEffect(() => {
    let menu = localStorage.getItem("activeMenu")
      ? localStorage.getItem("activeMenu")
      : "dashboard";
    setMenu(menu);
  }, [sidebarMenu]);

  const makeActive = (menuItem, e) => {
    // e.target.elements.className="active";
    localStorage.setItem("activeMenu", menuItem);
    setSidebarMenu(localStorage.getItem("activeMenu"));
    setMenu(menuItem);
  };
  return (
    <React.Fragment>
      <nav className="pc-sidebar">
        <div className="navbar-wrapper">
          <div className="m-header">
            <Link href="/">
              <img
                src="/images/logo-dark.svg"
                className="img-fluid logo-lg"
                alt="logo"
              />
              <span className="badge bg-light-success rounded-pill ms-2 theme-version">
                v9.0
              </span>
            </Link>
          </div>
          <div className="navbar-content">
            <ul className="pc-navbar">
              {/* <li className="pc-item pc-caption" data-menu-id="menu1">
                <label>Navigation</label>
              </li> */}
              <li
                className={
                  router.pathname == "/" ? "pc-item active" : "pc-item"
                }
                data-menu-id="menu2"
              >
                <Link href="/" className="pc-link">
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-status-up"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Dashboard</span>
                </Link>
              </li>

              <li
                className={
                  router.pathname == "/doctor" ||
                  router.pathname == "/doctor/list" ||
                  router.pathname == "/schedule/list" ||
                  router.pathname == "/schedule"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu3"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-user-add"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Doctor Manager</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("doctor_create") ? (
                    <li
                      className={
                        router.pathname == "/doctor"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/doctor" className="pc-link">
                        Add Doctor
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("doctor_list") ? (
                    <li
                      className={
                        router.pathname == "/doctor/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/doctor/list" className="pc-link">
                        Doctors list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("schedule_create") ? (
                    <li
                      className={
                        router.pathname == "/schedule"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/schedule" className="pc-link">
                        Add Schedule
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("schedule_list") ? (
                    <li
                      className={
                        router.pathname == "/schedule/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/schedule/list" className="pc-link">
                        Schedule list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
              {/* <li className="pc-item pc-hasmenu">
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-notification-status"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Schedule</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("schedule_create") ? (
                    <li className="pc-item">
                      <Link href="/schedule" className="pc-link">
                        Add Schedule
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("schedule_list") ? (
                    <li className="pc-item">
                      <Link href="/schedule/list" className="pc-link">
                        Schedule list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li> */}

              <li
                className={
                  router.pathname == "/appointment" ||
                  router.pathname == "/appointment/list" ||
                  router.pathname == "/prescription" ||
                  router.pathname == "/appointment/calendar-view"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu4"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-calendar-1"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Appointments</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("appointment_list") ? (
                    <li
                      className={
                        router.pathname == "/appointment"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/appointment" className="pc-link">
                        Add Appointment
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("appointment_create") ? (
                    <li
                      className={
                        router.pathname == "/appointment/list" ||
                        router.pathname == "/prescription"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/appointment/list" className="pc-link">
                        Appointment list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("calendar_list") ? (
                    <li
                      className={
                        router.pathname == "/appointment/calendar-view"
                          ? "pc-item active"
                          : "pc-item"
                      }
                      onClick={(e) => newSideBarHide(e)}
                    >
                      <Link
                        href="/appointment/calendar-view"
                        className="pc-link"
                      >
                        Calendar View
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>

              <li
                className={
                  router.pathname == "/patient" ||
                  router.pathname == "/patient/list" ||
                  router.pathname == "/document/list" ||
                  router.pathname == "/document"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu5"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-user-square"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Patient Manager</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("patient_create") ? (
                    <li
                      className={
                        router.pathname == "/patient"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/patient" className="pc-link">
                        Add Patients
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("patient_list") ? (
                    <li
                      className={
                        router.pathname == "/patient/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/patient/list" className="pc-link">
                        Patients list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("document_create") ? (
                    <li
                      className={
                        router.pathname == "/document"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/document" className="pc-link">
                        Add Document
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("document_list") ? (
                    <li
                      className={
                        router.pathname == "/document/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/document/list" className="pc-link">
                        Document list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>

              {/* <li className="pc-item pc-hasmenu">
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-document"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Documents</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("document_create") ? (
                    <li className="pc-item">
                      <Link href="/document" className="pc-link">
                        Add Document
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("document_list") ? (
                    <li className="pc-item">
                      <Link href="/document/list" className="pc-link">
                        Document list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li> */}

              <li
                className={
                  router.pathname == "/prescription/list" ||
                  router.pathname == "/medicine" ||
                  router.pathname == "/medicine/list"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu6"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-text-align-justify-center"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Prescription</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("prescription_list") ? (
                    <li
                      className={
                        router.pathname == "/prescription/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/prescription/list" className="pc-link">
                        Prescription List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("medicine_create") ? (
                    <li
                      className={
                        router.pathname == "/medicine"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/medicine" className="pc-link">
                        Add Medicine
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("medicine_list") ? (
                    <li
                      className={
                        router.pathname == "/medicine/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/medicine/list" className="pc-link">
                        Medicine list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>

              <li
                className={
                  router.pathname == "/bill/list" ||
                  router.pathname == "/bill" ||
                  router.pathname == "/service" ||
                  router.pathname == "/service/list"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu7"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-notification-status"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Billing Manager</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("bill_create") ? (
                    <li
                      className={
                        router.pathname == "/bill"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/bill" className="pc-link">
                        Add Billing
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("bill_list") ? (
                    <li
                      className={
                        router.pathname == "/bill/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/bill/list" className="pc-link">
                        Billing list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("service_create") ? (
                    <li
                      className={
                        router.pathname == "/service"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/service" className="pc-link">
                        Add Service
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("service_list") ? (
                    <li
                      className={
                        router.pathname == "/service/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/service/list" className="pc-link">
                        Service List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>

              <li
                className={
                  router.pathname == "/staff/list" ||
                  router.pathname == "/staff"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu8"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-user"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">User Manager</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("staff_create") ? (
                    <li
                      className={
                        router.pathname == "/staff"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/staff" className="pc-link">
                        Add User
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("staff_list") ? (
                    <li
                      className={
                        router.pathname == "/staff/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/staff/list" className="pc-link">
                        User list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
              <li
                className={
                  router.pathname == "/role/list" || router.pathname == "/role"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu9"
              >
                {permissions?.includes("role_list") ? (
                  <>
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="pc-link"
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-notification-status"></use>
                        </svg>
                      </span>
                      <span className="pc-mtext">Role Manager</span>
                      <span className="pc-arrow">
                        <i class="ti ti-chevron-right"></i>
                      </span>
                    </Link>
                    <ul className="pc-submenu">
                      {permissions?.includes("role_create") ? (
                        <li
                          className={
                            router.pathname == "/role"
                              ? "pc-item active"
                              : "pc-item"
                          }
                        >
                          <Link href="/role" className="pc-link">
                            Add Role
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {permissions?.includes("role_list") ? (
                        <li
                          className={
                            router.pathname == "/role/list"
                              ? "pc-item active"
                              : "pc-item"
                          }
                        >
                          <Link href="/role/list" className="pc-link">
                            Role list
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </>
                ) : (
                  ""
                )}
              </li>
              {permissions?.includes("speciality_list") ? (
                <li
                  className={
                    router.pathname == "/specialities"
                      ? "pc-item active"
                      : "pc-item"
                  }
                  data-menu-id="menu10"
                >
                  <Link href="/specialities" className="pc-link">
                    <span className="pc-micon">
                      <svg className="pc-icon">
                        <use xlinkHref="#custom-shapes"></use>
                      </svg>
                    </span>
                    <span className="pc-mtext">Specialities</span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              <li
                className={
                  router.pathname == "/bed-manager/bed/list" ||
                  router.pathname == "/bed-manager/bed" ||
                  router.pathname == "/bed-manager/bed-assign/list" ||
                  router.pathname == "/bed-manager/bed-assign/list"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu11"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-fatrows"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Bed Manager</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("bed_create") ? (
                    <li
                      className={
                        router.pathname == "/bed-manager/bed"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/bed-manager/bed" className="pc-link">
                        Add Bed
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("bed_list") ? (
                    <li
                      className={
                        router.pathname == "/bed-manager/bed/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/bed-manager/bed/list" className="pc-link">
                        Bed list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("bed_assign_list") ? (
                    <li
                      className={
                        router.pathname == "/bed-manager/bed-assign"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/bed-manager/bed-assign" className="pc-link">
                        Bed Assign
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("bed_assign_list") ? (
                    <li
                      className={
                        router.pathname == "/bed-manager/bed-assign/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link
                        href="/bed-manager/bed-assign/list"
                        className="pc-link"
                      >
                        Assign List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>

              <li
                className={
                  router.pathname == "/notice/list"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu12"
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="pc-link"
                >
                  <span className="pc-micon">
                    <svg className="pc-icon">
                      <use xlinkHref="#custom-presentation-chart"></use>
                    </svg>
                  </span>
                  <span className="pc-mtext">Notice Board</span>
                  <span className="pc-arrow">
                    <i data-feather="chevron-right"></i>
                  </span>
                </Link>
                <ul className="pc-submenu">
                  {permissions?.includes("notice_create") ? (
                    <li
                      className={
                        router.pathname == "/notice"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/notice" className="pc-link">
                        Add Notice
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {permissions?.includes("notice_list") ? (
                    <li
                      className={
                        router.pathname == "/notice/list"
                          ? "pc-item active"
                          : "pc-item"
                      }
                    >
                      <Link href="/notice/list" className="pc-link">
                        Notice list
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
              <li
                className={
                  router.pathname == "/settings" ||
                  router.pathname == "/settings/layout"
                    ? "pc-item pc-hasmenu active"
                    : "pc-item pc-hasmenu"
                }
                data-menu-id="menu12"
              >
                {permissions?.includes("setting_list") ||
                permissions?.includes("layout_list") ? (
                  <>
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="pc-link"
                    >
                      <span className="pc-micon">
                        <svg className="pc-icon">
                          <use xlinkHref="#custom-presentation-chart"></use>
                        </svg>
                      </span>
                      <span className="pc-mtext">Settings Manager</span>
                      <span className="pc-arrow">
                        <i class="ti ti-chevron-right"></i>
                      </span>
                    </Link>
                    <ul className="pc-submenu">
                      {permissions?.includes("setting_list") ? (
                        <li
                          className={
                            router.pathname == "/settings"
                              ? "pc-item active"
                              : "pc-item"
                          }
                        >
                          <Link href="/settings" className="pc-link">
                            General
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {permissions?.includes("layout_list") ? (
                        <li
                          className={
                            router.pathname == "/settings/layout"
                              ? "pc-item active"
                              : "pc-item"
                          }
                        >
                          <Link href="/settings/layout" className="pc-link">
                            Layout
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Sidebar;
